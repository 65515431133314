<template>
  <div class="payment routes">
    <v-container>
      <v-row>
        <ChargeWallet />
        <PaymentList />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChargeWallet from "../../components/payment/ChargeWallet.vue";
import PaymentList from "../../components/payment/PaymentList.vue";
export default {
  components: { PaymentList, ChargeWallet },
};
</script>

<style lang="scss">
@import "./payment.scss";
</style>
