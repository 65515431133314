<template>
  <div>
    <!-- if user connect wallet show dropdown -->
    <v-menu
      v-if="getTronConnectionStatus && token && user"
      dark
      bottom-right
      transition="scale-transition"
      content-class="profile-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" class="profile-btn">
          <span class="wallet-btn d-none d-lg-block">
            {{ getDefaultAddress.base58 }}
          </span>
          <v-icon class="wallet-icon d-block d-lg-none">
            mdi mdi-account
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            @click="$router.push('/profile')"
            class="d-flex align-center"
          >
            <span> Profile </span>
            <v-spacer></v-spacer>
            <v-icon>mdi-account</v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="$router.push('/assets')"
            class="d-flex align-center"
          >
            <span> Assets </span>
            <v-spacer></v-spacer>
            <v-icon>mdi-shopping</v-icon></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="$router.push({ name: 'payment' })"
            class="d-flex align-center"
            ><span> Payments </span>
            <v-spacer></v-spacer>
            <v-icon>mdi-credit-card-outline</v-icon></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="userLogout" class="d-flex align-center"
            ><span> Logout </span>
            <v-spacer></v-spacer>
            <v-icon>mdi-logout</v-icon></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- if user dont connected wallet or dont have token show modal -->
    <v-dialog
      v-model="dialog"
      width="500"
      dark
      content-class="wallet-modal"
      v-else
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" class="d-none d-lg-block">
          <span class="wallet-btn">
            {{
              getTronConnectionStatus
                ? getDefaultAddress.base58
                : "Connect to Wallet"
            }}
          </span>
        </v-btn>

        <v-icon v-bind="attrs" v-on="on" class="d-block d-lg-none wallet-icon">
          mdi mdi-wallet-outline
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h6 d-flex justify-space-between">
          {{
            getTronConnectionStatus
              ? user_exist
                ? "Sign In"
                : "Registration"
              : "Connect to Wallet"
          }}

          <v-icon @click="dialog = false">mdi mdi-close-circle-outline</v-icon>
        </v-card-title>

        <v-card-text class="card-text">
          <img
            :src="require('../assets/images/Logo_Azollite.png')"
            alt="Logo"
            class="logo"
          />

          <!-- tron link image -->
          <div class="wallet" v-if="getTronConnectionStatus === false">
            <img
              :src="require('../assets/images/Tronlink.svg')"
              alt="tronlink"
            />
            <span> TronLink Wallet </span>
          </div>

          <!-- Login Or Register -->
          <div class="address" v-if="getTronConnectionStatus">
            <div class="tip-text">Connected with Tronlink</div>
            <div class="address-text">
              <span> {{ getDefaultAddress.base58 }} </span>
            </div>
          </div>

          <div class="auth" v-if="user_checked">
            <Login v-if="user_exist" />
            <Register v-else />
          </div>

          <span
            class="tron-link-tips"
            v-if="getTronAccessStatus === false || getLoginStatus === false"
          >
            <template v-if="getTronAccessStatus === false">
              Please Install Tron Wallet From tronStatus:{{
                getTronAccessStatus
              }},loginStatus:{{ getLoginStatus }}
              <a
                :href="tronWebInstallAddress"
                target="_blank"
                rel="noopener noreferrer"
              >
                Here
              </a>
            </template>

            <template v-if="getLoginStatus === false">
              Please Login into your Tron Wallet account!
            </template>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Login from "./Login.vue";
import Register from "./Register.vue";
export default {
  components: { Login, Register },
  data() {
    return {
      dialog: false,
      loading: false,

      user_checked: false,
      user_exist: false,

      tronWebInstallAddress:
        "https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec",
    };
  },

  computed: {
    ...mapGetters([
      "getTronWeb",
      "getTronConnectionStatus",
      "getTronAccessStatus",
      "getLoginStatus",
      "getDefaultAddress",
      "token",
      "user",
      "walletModal",
    ]),
  },
  created() {},

  methods: {
    ...mapActions(["userExist", "logout"]),

    /**
     * Check User Exist
     */
    async check_user_exist() {
      if (this.dialog && this.getDefaultAddress.base58) {
        this.loading = true;

        //
        let wallet_address = this.getDefaultAddress.base58;

        //
        await this.userExist({ wallet_address }).then((res) => {
          if (res) {
            console.log(res);
            this.user_checked = true;
            this.user_exist = res.userExists;
            console.log(res);
          }
        });

        //
        this.loading = false;
      }
    },
    userLogout() {
      this.logout();
      this.dialog = false;
      if (this.$route.path !== "/") {
        this.$router.replace("/");
      }
    },
  },

  watch: {
    getTronConnectionStatus() {
      this.check_user_exist();
    },
    dialog() {
      this.check_user_exist();
    },
    walletModal() {
      if (this.walletModal) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-btn {
  background: transparent !important;
  @media only screen and(min-width: 1265px) {
    background: #272727 !important;
  }
}
.v-list-item {
  &:hover {
    background: rgb(54, 54, 54);
  }
}
.card-text {
  height: auto !important;
  max-height: 520px;
  overflow: auto;
}
</style>
