<template>
  <section class="contact" id="contact">
    <h1 class="wrapper-head text-center">Contact Us</h1>
    <div class="overlay"></div>
    <v-container>
      <v-row class="justify-center">
        <v-col lg="6">
          <v-form
            @submit.prevent="validate"
            ref="contact_us"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  type="email"
                  :rules="rules.email_rules"
                  v-model="form.email"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Message"
                  :rules="rules.message_rules"
                  v-model="form.body"
                  solo
                ></v-textarea>
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              x-large
              class="px-7 grey white--text"
              :loading="loading"
              :disabled="loading"
            >
              Submit
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4 "
      elevation="100"
      absolute
      centerd
      shaped
      top
      transition="slide-y-transition"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: true,
      loading: false,
      message: "",
      snackbar: false,
      form: {
        email: "",
        body: "",
        name: "ee",
      },
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  methods: {
    ...mapActions(["sendContactForm"]),

    initData() {
      this.form = {
        email: "",
        body: "",
        name: "ee",
      };

      this.$refs.contact_us.reset();
    },

    validate() {
      this.$refs.contact_us.validate();

      this.$nextTick(async () => {
        if (this.valid) {
          this.loading = true;

          //
          await this.sendContactForm(this.form).then((res) => {
            this.snackbar = res;
            this.message = "your message send successfully";
            if (res) {
              this.initData();
            }
          });

          //
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contact {
  .wrapper-head {
    color: #927c58;
  }
}
</style>
