<template>
  <section class="sort-box px">
    <v-row class="white--text align-center">
        <v-col cols="6" md="2">
          <v-btn
            class="my-3 mr-10 white--text"
            outlined
            @click="toggleDrawer"
          >
            <v-icon left>mdi-filter</v-icon>Filters</v-btn
          >
        </v-col>
        <v-col cols="12" md="8" order="12" order-md="3" class="d-md-flex ">
          <h3 class="mr-2">
            <v-icon color="white" class="mb-1">mdi-sort-ascending</v-icon> Sort
            By :
          </h3>
          <v-menu open-on-hover bottom offset-y dark>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" dark v-bind="attrs" v-on="on" text>
                Price
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-btn text @click="makeSort('price', 'desc')"
                  >Most Expensive</v-btn
                >
              </v-list-item>
              <v-list-item>
                <v-btn text @click="makeSort('price', 'asc')"
                  >chipest</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-menu open-on-hover bottom offset-y dark>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" text>
                Size
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-btn text @click="makeSort('size_id', 'asc')">Minmum size</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn text @click="makeSort('size_id', 'desc')">Maximum size</v-btn>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-menu open-on-hover bottom offset-y dark>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" text>
                Time
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-btn text @click="makeSort('updated_at', 'asc')"
                  >Newest</v-btn
                >
              </v-list-item>
              <v-list-item>
                <v-btn text @click="makeSort('updated_at', 'desc')"
                  >Oldest</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn color="white" text @click="resetSort">reset sort</v-btn>
        </v-col>
        <v-col cols="6" md="2" order="7" order-md="11" >
          <div class="mr-3 text-end">{{ total }} <span class="">result(s)</span></div>
        </v-col>
      </v-row>
  </section>
</template>

<script>
export default {
  props:['total'],
methods:{
  toggleDrawer(){
    this.$emit('toggleDrawer')
  },
  makeSort(order,sort){
    this.$emit('makeSort', order,sort)
  },
  resetSort(){
    this.$emit('resetSort');
  }
}
}
</script>

<style>

</style>