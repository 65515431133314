<template>
  <section class="gift sec-wrapper">
    <h1 class="wrapper-head text-center">OUR FEATURES</h1>
    <v-row class="row mx-auto">
      <v-col cols="12" md="6" class="cols">
        <div class="d-flex" style="height: 600px">
          <v-img
            src="@/assets/images/More_Mode.jpg"
            max-height="590px"
            contain
            class="br-5 mb-2 g-image"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="cols">
        <section
          class="d-flex flex-column justify-center align-center align-md-start"
        >
          <div class="item white mb-8">
            <h3 class="header">Game Expansion For Developers </h3>
            <p class="text grey--text">
              We intend to work with other developers to come up with innovative methods to bring the LOA world to life. In various stages of development
            </p>
          </div>
          <div class="item white mb-8">
            <h3 class="header">Dependent NFTS and Lands</h3>
            <p class="text grey--text">
              In each land, some kind of valuable NFTs are mined from the depths of the land that other lands need and these NFTs can be mined only in certain lands
            </p>
          </div>
          <div class="item white mb-3">
            <h3 class="header">City Management</h3>
            <p class="text grey--text">
              players will experience features such as city management, upgrading and evolving structures and strategies, daily quests, upgrading tools, and exploring the world, all in a new and unique way of PVE
            </p>
          </div>
        </section>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  methods: {
    observeAnimation() {
      const cards = document.getElementsByClassName("cols");
      let observer = new IntersectionObserver((enteries) => {
        enteries.forEach((entery) => {
          if (entery.isIntersecting) {
            //if object is exsist in view port and threshold is 0
            entery.target.classList.add("observed");
          }
        });
      });
      for (let i = 0; i < cards.length; i++) {
        observer.observe(cards[i]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gift {
  .wrapper-head {
    color: #907851;
  }
  background: #000000;

  .row {
    width: 80%;
    .cols {
      .g-image {
        transition: all 0.8s ease;
        &:hover {
          transition: all 0.8s ease;
          transform: scale(1.2);
        }
      }
      &:nth-child(1) {
        transform: translateX(180px);
        opacity: 0;
      }
      &:nth-child(2) {
        transform: translateX(-180px);
        opacity: 0;
      }
      &.observed {
        transition: all 1.5s ease;
        transform: translateY(0);
        opacity: 1;
      }
    }
    .item {
      max-width: 450px;
      min-height: 160px;
      border-radius: 10px;
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and(max-width: 500px) {
        padding: 0 15px;
      }
      .header {
        color: black;
        font-size: 20px;
        margin-bottom: 10px;
        @media only screen and(max-width: 500px) {
          font-size: 20px;
        }
      }
      .text {
        font-size: 14px;
        color: rgb(109, 109, 109) !important;
        @media only screen and(max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
