import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/style/main.scss'
import LoadingSpinner from './components/LoadingSpinner.vue';
import DetailCard from './components/shop/DetailCard.vue';
import QuastionCard from './components/QuastionCard.vue';
import LandMapShow from './components/map/LandMapShow.vue';

Vue.config.productionTip = false
Vue.component('loading-spinner', LoadingSpinner);
Vue.component('DetailCard', DetailCard);
Vue.component('QuastionCard', QuastionCard);
Vue.component('LandMapShow', LandMapShow);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
