import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getLands(_, info) {
    // console.log('info:',info);
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/sales`, {
          params: info,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getLandDetail(_, id) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/lands/${id}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getCategories() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/categories`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getSubCategories(_,payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/categories/${payload}/subcategories`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getTags() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/market/tags`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  
  getMaxCordinate() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/max-coordinate`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getPriceRange() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`market/range-price`)
        .then((result) => {
          console.log(result);
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  buy(_,payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .put(`/users/sales/${payload}`,{buyer_land_id:''})
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
