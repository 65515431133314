<template>
  <section class="land-card">
    <v-row class="row">
      <v-col
        v-for="(item, i) in lands"
        :key="i"
        cols="12"
        sm="6"
        md="3"
        class=""
      >
        <section class="pa-2 card" @click="showDetails(item)">
          <v-img
            :src="item.record_detail.art_portrait"
            max-height="max-content"
            min-height="200"
          ></v-img>
          <v-divider class="my-2"></v-divider>
          <div class="mt-1">
            <div class="d-felx flex-column">
              <div class="d-flex align-center">
                <p class="grey--text mr-1" style="font-size:16px">Price:</p>
                  <p class="green--text mr-1" style="font-weight:900">
                     {{ item.price.usdt.price }}$
                  </p>
                <span v-html="item.record_detail.priceDescription"></span>
                <v-spacer></v-spacer>
                <v-tooltip top color="grey darken-3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="showDetails(item)"
                      ><v-icon color="white"
                        >mdi-information-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>More Information</span>
                </v-tooltip>
              </div>
              <h1 class="d-flex mt-n2">
                <p class="grey--text mr-1">Size:</p>
                <p class="orange--text">{{ item.record_detail.size }}</p>
              </h1>
            </div>
          </div>
          <button class="shop-button black--text" @click.stop="buyLand(item.id)">Buy Now</button>
        </section>
      </v-col>
    </v-row>

    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="600">
      <DetailCard
        @close="detailDialog = false"
        :selectedLand="selectedLand"
        :isLoading="isLoading"
        @buy="buyLand"
      ></DetailCard>
    </v-dialog>
    <!--detail dialog -->

    
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["lands"],
  data() {
    return {
      detailDialog: false,
      isLoading:false,
       selectedLand : {
        parentCategory:{
          title:''
        },
        category:{
          title:''
        },
        sale:{
          status:'',
          price:'',
          priceDescription:''
        },
        map:{
          art_portraite:''
        },
      },
    };
  },
  created(){
  },
  methods: {
    ...mapActions(["getLandDetail"]),
     
    buyLand(id) {
      this.$emit("buy", id);
    },
    showDetails(item) {
      this.detailDialog = true;
      this.isLoading = true;
      this.getLandDetail(item.record_detail.id).then((result) => {
        this.selectedLand = result.result;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.land-card {
  .row {
    .card {
      // min-height: 450px;
      border: 2px solid rgb(212, 212, 212);
      border-radius: 3px;
          transition: all .3s ease;
          cursor: pointer;
        &:hover {
          transform: scale(1.004);
          box-shadow: 0 0 20px rgba(255, 255, 255, 0.37);
          transition: all .3s ease;
        }
      .shop-button {
        border-radius: 5px;
        padding: 10px;
        background: #ff6600;
        color: white;
        width: 100%;
      }
    }
  }
}
</style>
