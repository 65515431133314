<template>
  <section class="details">
    <v-card class="card" dark>
      <v-card-title class="card-title grey darken-4">
        <section class="w-100 d-flex justify-space-between align-center">
          <h3 class="white--text">Land Details</h3>
          <v-btn color="white" outlined icon small @click="close">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </section>
      </v-card-title>
      <v-card-text class="py-3 card-text">
        <div class="loading" v-if="isLoading">
          <loading-spinner></loading-spinner>
        </div>
        <section class="contant d-flex flex-column" v-else>
          <div class="haed mb-2">
            <h2 class="mr-2 orange--text">
              {{ selectedLand.name ? selectedLand.name : "No Name" }}
            </h2>
            <span class="green--text"
              >(
              {{
                selectedLand.parentCategory
                  ? selectedLand.parentCategory.title
                  : "null"
              }}
              /
              {{ selectedLand.category ? selectedLand.category.title : "null" }}
              )</span
            >
          </div>
          <v-img
            :src="selectedLand.map.art_portrait"
            max-height="200"
            max-width="250"
            min-height="145"
            contain
            class="mx-auto"
          ></v-img>
          <v-divider class="my-4"></v-divider>

          <v-expansion-panels multiple dark>
            <v-expansion-panel>
              <v-expansion-panel-header class="orange--text"
                >Story</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div class="description">
                  <div class="px-3">
                    <p class="grey--text mb-1">
                      {{
                        selectedLand.parentCategory
                          ? selectedLand.parentCategory.description
                          : "null"
                      }}
                    </p>
                    <p class="grey--text">
                      {{
                        selectedLand.category
                          ? selectedLand.category.description
                          : "null"
                      }}
                    </p>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="orange--text"
                >Owner</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <h3 class="text-center" v-if="!selectedLand.owner">
                  No Owner !
                </h3>
                <div class="Owner" v-else>
                  <div class="d-sm-flex px-3">
                    <v-img
                      src="https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg"
                      max-height="100"
                      max-width="100"
                      class="mr-5"
                    ></v-img>

                    <span class="">
                      <h4 class="yellow--text">
                        Name:
                        <span class="grey--text">{{
                          selectedLand.owner.name
                        }}</span>
                      </h4>
                      <h4 class="yellow--text">
                        Email:
                        <span class="grey--text">{{
                          selectedLand.owner.email
                        }}</span>
                      </h4>
                      <h4 class="yellow--text">
                        ID:
                        <span class="grey--text">{{
                          selectedLand.owner.id
                        }}</span>
                      </h4>
                      <h4 class="yellow--text wallet-address">
                        Wallet:
                        <span class="grey--text">{{
                          selectedLand.owner.wallet_address
                        }}</span>
                      </h4>
                    </span>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="orange--text"
                >Sell</v-expansion-panel-header
              >
              <v-expansion-panel-content v-if="!selectedLand.sale">
                <h1 class="grey--text text-center">No sale information!</h1>
              </v-expansion-panel-content>

              <v-expansion-panel-content v-else>
                <div class="sell">
                  <div class="px-3">
                    <div class="d-sm-flex">
                      <h4 class="yellow--text mr-10">
                        Status:
                        <span class="grey--text">{{
                          selectedLand.sale.status
                        }}</span>
                      </h4>
                      <h4 class="yellow--text mr-4">
                        Price:
                        <span class="grey--text"
                          >{{ selectedLand.sale.price.usdt.price }} $</span
                        >
                      </h4>
                      <h4 class="yellow--text">
                        <span
                          class="red--text"
                          v-html="selectedLand.sale.priceDescription"
                        ></span>
                      </h4>
                     
                    </div>
                    <v-divider class="mb-2"></v-divider>
                    <h4 class="yellow--text mt-1">Transfer List</h4>
                    <section class="transfer-list">
                      <h5 v-if="selectedLand.sale.transfers_list.length <= 0">
                        No items ...
                      </h5>
                      <div
                        v-for="(item, i) in selectedLand.sale.transfers_list"
                        :key="i"
                      >
                        <span class="mr-1">{{ i }} -</span><span>title</span>
                      </div>
                    </section>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="orange--text"
                >Land</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div class="land">
                  <div class="px-3">
                    <section class="d-sm-flex">
                      <div class="yellow--text mr-6">
                        Lat:
                        <span class="grey--text">{{
                          selectedLand.map.coordinateX
                        }}</span>
                      </div>
                      <div class="yellow--text mr-6">
                        Long:
                        <span class="grey--text">{{
                          selectedLand.map.coordinateY
                        }}</span>
                      </div>
                      <div class="yellow--text mr-6">
                        Width:
                        <span class="grey--text">{{ mapWidth }}</span>
                      </div>
                      <div class="yellow--text mr-6">
                        Length:
                        <span class="grey--text">{{
                          selectedLand.map.height
                        }}</span>
                      </div>
                    </section>
                    <v-divider class="grey mb-2"></v-divider>
                    <LandMapShow :selectedLand="selectedLand"></LandMapShow>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="orange--text"
                >Specifications</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div class="tags">
                  <section class="">
                    <h5 v-if="selectedLand.tags.length <= 0">No items ...</h5>
                    <div v-for="(item, i) in selectedLand.tags" :key="i">
                      <section class="d-flex">
                        <span class="mr-1 yellow--text">{{ item.title }}</span>
                        <v-spacer></v-spacer>
                        <span class="mr-3 yellow--text" style="min-width: 80px"
                          >Total:
                          <span class="grey--text">{{ item.total }}</span></span
                        >
                        <span class="mr-1 yellow--text" style="min-width: 80px">
                          Sold:
                          <span class="grey--text">{{ item.sales }}</span></span
                        >
                      </section>
                      <v-divider class="grey mb-2"></v-divider>
                    </div>
                  </section>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </section>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="$route.path === '/shop'"
          color="#ff6600"
          min-width="150"
          class="py-6 mx-auto black--text"
          style="width: 95%"
          :loading="isLoading"
          @click="buyLand(selectedLand)"
        >
          Buy Now
        </v-btn>
        <v-btn
        v-if="$route.path === '/assets'"
          color="#ff6600"
          min-width="150"
          class="py-6 mx-auto black--text"
          style="width: 97%;"
          @click="addToSale(selectedLand)"
        >
          Sell this item
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
export default {
  props: ["selectedLand", "isLoading"],
  data() {
    return {};
  },
  computed: {
    mapWidth() {
      if (this.selectedLand.map) {
        return this.selectedLand.map.width;
      } else {
        return "bia";
      }
    },
  },
  created() {},
  methods: {
    buyLand(item) {
      this.$emit("buy", item.sale.id);
    },
    addToSale(item) {
      this.$emit("sell", item);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .card {
    background: black;
    .transfer-list {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-text {
      height: 500px !important;
      overflow: auto;
      .wallet-address {
        @media only screen and(max-width: 480px) {
          max-width: 170px;
        }
      }
    }
  }
}
</style>
