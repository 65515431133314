<template>
  <div class="auth">
    <v-divider class="my-3" />
    <v-form
      @submit.prevent="validate"
      ref="login"
      v-model="valid"
      lazy-validation
    >
      <div class="form-group">
        <label>Email</label>
        <v-text-field
          type="email"
          :rules="rules.email_rules"
          v-model="form.email"
          outlined
          dense
        ></v-text-field>
      </div>

      <div class="form-group">
        <label>Password</label>
        <v-text-field
          type="password"
          :rules="rules.password_rules"
          v-model="form.password"
          outlined
          dense
        ></v-text-field>
      </div>

      <v-btn
        type="submit"
        class="mt-5"
        color="white"
        outlined
        :loading="loading"
        :disabled="loading"
      >
        Login
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      valid: true,
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rules", "getDefaultAddress"]),
  },

  methods: {
    ...mapActions(["login"]),

    validate() {
      this.$refs.login.validate();

      this.$nextTick(() => {
        if (this.valid) {
          this.loading = true;
          let content = this.form;
          content.wallet_address = this.getDefaultAddress.base58;

          //
          this.login(content).then((res) => {
            if (res) {
              this.loading = false;
              this.$emit("closeModal");
            } else {
              this.loading = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style></style>
