<template>
  <div class="main-social-icons d-flex pt-10 pb-5">
    <a target="_blank" rel="noopener noreferrer">
      <v-icon>mdi-instagram</v-icon>
    </a>
    <a target="_blank" rel="noopener noreferrer">
      <v-icon>mdi-facebook</v-icon>
    </a>
    <a target="_blank" rel="noopener noreferrer">
      <v-icon>mdi-twitter</v-icon>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.main-social-icons {
  justify-content: center;
  a {
    padding: 15px;
    background: white;
    border-radius: 50%;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    i {
      color: #40b5db !important;
      font-size: 35px !important;
    }
  }
}
</style>
