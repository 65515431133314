<template>
  <section class="fan-fav sec-wrapper">
    <h1 class="wrapper-head text-center">Lands Of Azolite</h1>
    <div style="width:90%; max-width:1100px" class="mx-auto ">
      <p class="text-center">
        Explore the wide and varied locations in search of buried treasures, and mine them to your advantage or trade them via the exchange. In the Lands of Azollite, the basic goal is to build a city and mine ancient treasures and resources. It sounds simple enough, but can be quite challenging. For instance, to build mining structures, you need to efficiently manage your resources in order to construct them and even trade with other players in the marketplace to afford the required currencies.
      </p>
    </div>
    <v-slide-group class="pa-4 mt-12" center-active show-arrows>
      <v-slide-item v-for="(item, i) in items" :key="i">
        <v-card
          color="transparent"
          class="my-4 mx-5"
          
        >
          <v-row class="fill-height" align="center" justify="center">
            <section class="card d-flex flex-column align-center pa-1">
              <v-img
                :src="
                  require(`@/assets/images/Featured Fighters/${item.image}`)
                "
                class="br-5"
                
              ></v-img>
            </section>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          image: "Bank.jpg",
        },
        {
          image: "Command_Center.jpg",
        },
        {
          image: "Miner.jpg",
        },
          {
            image: "Strategy_Hall.jpg",
          },
        {
          image: "Reactor.jpg",
        },
        {
          image: "Supply_Depot.jpg",
        },
        
      ],
    };
  },
  mounted() {
    this.observeAnimation();
  },
  methods: {
    observeAnimation() {
      const cards = document.getElementsByClassName("cols");
      console.log(cards);
      let observer = new IntersectionObserver((enteries) => {
        enteries.forEach((entery) => {
          if (entery.isIntersecting) {
            //if object is exsist in view port and threshold is 0
            entery.target.classList.add("observed");
          }
        });
      });
      for (let i = 0; i < cards.length; i++) {
        observer.observe(cards[i]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fan-fav {
  background: #000000;
  color: #8d7957;
  .card {
    background: #937341;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    height: 450px;
    width: 325px;
    @media only screen and(max-width: 500px){
      height: 220px;
      width: 150px;
    }
    // .v-img{
    //   max-height: 40px;
    // }
  }
  .observe {
    transform: translateY(200px);
    opacity: 0;
    &.observed {
      transition: all 1.5s ease;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

::v-deep .mdi-chevron-left::before,
::v-deep .mdi-chevron-right::before {
  background: rgba(255, 255, 255, 0.226) !important;
  padding: 10px;
  border-radius: 50%;
  color: white;
}
.v-slide-group{
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}
</style>
