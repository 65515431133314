<template>
  <section class="footer d-flex justify-center">
    <div class="content">
      <v-row>
        <v-col cols="12" sm="8">
          <div>
            <v-img
              src="@/assets/images/Logo_Azollite.png"
              max-width="120px"
              @click="$route.path != '/' ? $router.push('/') : ''"
              style="cursor:pointer"
            ></v-img>
            <div class="icons d-flex mt-12">
              <v-icon>mdi-instagram</v-icon>
              <v-icon>mdi-facebook</v-icon>
              <v-icon>mdi-twitter</v-icon>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="d-flex flex-column">
            <router-link to="/shop"> Shop </router-link>
            <a href="https://doc.landsofazollite.com/" target="_blank">
              Our Story
            </a>

            <a href="/#contact" class="grey--text">Contact</a>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding: 60px 0;
  background: #100c06;
  .content {
    width: 85%;

    .icons {
      .v-icon {
        color: white;
        font-size: 25px;
      }
    }
    a {
      color: grey;
      margin-bottom: 10px;
    }
  }
}
</style>
