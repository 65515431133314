<template>
  <section class="shop routes">
    <!-- filters -->
    <v-navigation-drawer v-model="drawer" width="290px" app hide-overlay>
      <section class="toolbar-content br-12 d-flex flex-column">
        <section
          class="drawer-header py-3 px-2 d-flex justify-space-between align-center"
        >
          <div class="d-flex">
            <v-icon dark size="30" class="mr-2">mdi-filter-variant</v-icon>
            <h3 class="white--text">Filters</h3>
          </div>
          <v-btn fab dark small @click="drawer = false"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </section>

        <v-divider class="grey lighten-2"></v-divider>
        <Filters @submitFilters="submitFilters" />
      </section>
    </v-navigation-drawer>
    <!-- filters -->

    <!-- store-box -->
    <section class="main-shop mx-auto pa-2">
      <SortBox
        @toggleDrawer="drawer = !drawer"
        @makeSort="makeSort"
        @resetSort="resetSort"
        :total="total"
      />

      <v-divider class="grey lighten-2 mb-4"></v-divider>
      <div class="loading" v-if="isLoading">
        <loading-spinner></loading-spinner>
      </div>
      <div
        class="d-flex justify-center"
        v-else-if="!isLoading && lands.length <= 0"
      >
        <h1 class="grey--text mb-2">No item to show!</h1>
      </div>

      <LandCard
        :lands="lands"
        @buy="buyLand"
        :buyLoading="buyLoading"
        :quastionDialog="quastionDialog"
        v-else
      />

      <v-divider class="white mt-2"></v-divider>
      <div class="table-paginate-box">
        <div class="table-pagination-total">
          {{ total }} <span class="">result(s)</span>
        </div>
        <div class="paginate-box">
          <v-pagination
            v-model="page"
            class="pagination"
            :length="lastPage"
            total-visible="5"
            @input="fetchLandLists()"
            color="blue"
            circle
          ></v-pagination>
        </div>
      </div>
    </section>
    <!-- store-box -->

    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to buy this item ? "
        :isLoading="buyLoading"
        @accept="accept"
      ></QuastionCard>
    </v-dialog>
    <!--quastion dialog -->

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="green accent-4 "
      dense
      centerd
      shaped
      top
      transition="slide-y-transition"
      style="position: fixed"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>
<script>
import { mapActions } from "vuex";
import LandCard from "../../components/shop/LandCard.vue";
import SortBox from "../../components/shop/SortBox.vue";
import Filters from "../../components/shop/Filters.vue";
export default {
  data() {
    return {
      drawer: false,
      lands: [],
      helperArray: [],
      // filter
      selectedSubCategory: 0,
      selectedSpecifications: [],
      //size
      // widthRange: [0, 0],
      // heightRange: [0, 0],
      //size

      name: null,
      // location
      cordinateXSelected: 0,
      cordinateYSelected: 0,
      selectedRadius: 0,
      // location

      // price
      priceRange: [0, 23300],
      // price
      // filter
      page: 1,
      lastPage: 0,
      total: 0,

      // sort
      selectedSort: null,
      selectedOrder: null,
      // sort

      snackbar: false,
      message: "",
      isLoading: false,
      buyLoading: false,
      quastionDialog: false,
      selectedItemId: null,
    };
  },
  components: {
    LandCard,
    SortBox,
    Filters,
  },
  created() {
    this.fetchLandLists();
  },
  methods: {
    ...mapActions(["getLands", "buy"]),
    fetchLandLists() {
      let params = {
        page: this.page,
        limit: 8,
        type: "Land",
        category_id: this.selectedSubCategory,
        name: this.name,
        coordinateX: this.cordinateXSelected,
        coordinateY: this.cordinateYSelected,
        radius: this.selectedRadius,
        from_price: this.priceRange[0],
        to_price: this.priceRange[1],
        tags: this.selectedSpecifications,
      };
      if (this.selectedSort != null) {
        params.sort = this.selectedSort;
        params.order = this.selectedOrder;
      }

      this.isLoading = true;
      this.getLands(params)
        .then((result) => {
          // console.log('items:',result);
          this.lands = result.data;
          console.log("lands:", this.lands);
          // result.data.forEach(element => {
          //   this.helperArray.push(element.record_detail);
          //   this.lands = this.helperArray;

          // });
          this.total = result.meta.total;
          this.lastPage = result.meta.last_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    submitFilters(
      selectedSubCategory,
      name,
      cordinateXSelected,
      cordinateYSelected,
      selectedRadius,
      priceRange,
      selectedSpecifications,
    ) {
      this.selectedSubCategory = selectedSubCategory;
      this.name = name;
      this.cordinateXSelected = cordinateXSelected;
      this.cordinateYSelected = cordinateYSelected;
      this.selectedRadius = selectedRadius;
      this.priceRange = priceRange
      this.selectedSpecifications = selectedSpecifications;
      this.fetchLandLists();
    },
    makeSort(order, sort) {
      this.selectedSort = sort;
      this.selectedOrder = order;
      this.fetchLandLists();
    },
    resetSort() {
      this.selectedSort = null;
      this.selectedOrder = null;
      this.fetchLandLists();
    },
    buyLand(id) {
      this.selectedItemId = id;
      this.quastionDialog = true;
    },
    accept() {
      this.buyLoading = true;
      this.buy(this.selectedItemId)
        .then((result) => {
          console.log(result);
          this.message = result.message;
          this.snackbar = true;
          this.fetchLandLists();
        })
        .finally(() => {
          this.buyLoading = false;
          this.quastionDialog = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shop {
  .main-shop {
    border: 2px solid grey;
    border-radius: 3px;
    background: rgb(15, 15, 15);
    width: 95%;
  }
  .v-navigation-drawer {
    background: rgb(0, 0, 0);
    border-right: 2px solid rgb(255, 255, 255);
    color: white !important;
    .toolbar-content {
      margin-top: 100px;
    }
  }
  .pagination {
    @media only screen and(max-width: 400px) {
      transform: scale(0.8);
    }
  }
}
</style>
