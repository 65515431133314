<template>
  <section class="callback-payment routes d-flex justify-center">
    <div class="card d-flex flex-column align-center" :style="`border:4px solid ${status == 10 ? 'green' : 'red'}`">
      <h5 v-if="!haveQuery">No Payment</h5>
      <div v-else>
        <h4 class="text-center mb-5 white--text">{{ message }}</h4>
        <h4 class="text-center mb-5 grey--text">Status: <span class="white--text">{{ statusCovertor(status) }}</span></h4>
        <h4 class="text-center mb-5 grey--text">Oreder Id: <span class="white--text">{{ orderId }}</span></h4>
      </div>
      <v-btn @click="$router.replace('/payment')" color="white" text >Back To Page</v-btn>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      haveQuery: false,
      status: "",
      message: "",
      orderId: "",
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      let orderId = this.$route.query.orderId;
      let status = this.$route.query.status;
      let message = this.$route.query.message;

      //
      if (orderId && status && message) {
        this.haveQuery = true;
        this.orderId = orderId;
        this.status = status;
        this.message = message;
      }
    },
    // Status convertur for show in payment status
    statusCovertor(status) {
      if (status == 1) return "Expired";
      if (status == 2) return "Canceled";
      if (status == 10) return "Success";
    },
  },
};
</script>

<style lang="scss" scoped>
.callback-payment {
  .card{
    width: 95%;
    max-width: 550px;
    border-radius: 15px;
    padding: 60px 20px;
    margin-top: 40px;
  }
}
</style>
