<template>
  <section class="callback-payment routes d-flex justify-center">
    <div
      class="card d-flex flex-column align-center"
      :class="verified ? 'border-success' : 'border-error'"
    >
      <h4
        class="white--text mb-5"
        :class="verified ? 'green--text' : 'error--text'"
      >
        <template v-if="isLoading"> Please wait ... </template>
        <template v-else>
          {{
            verified
              ? "Your Email Verified Successfully!"
              : "Email Not Verified"
          }}
        </template>
      </h4>
      <v-btn
        @click="$router.replace('/profile')"
        color="white"
        text
        :loading="isLoading"
        >Back To Page</v-btn
      >
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      verified: false,
      isLoading: false,
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    ...mapActions(["getEmailVerify"]),
    async checkQuery() {
      let hash = this.$route.query.hash;
      if (hash) {
        this.isLoading = true;
        await this.getEmailVerify(hash).then((res) => {
          if (res) {
            this.isLoading = false;
            this.verified = true;
          } else {
            this.isLoading = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.callback-payment {
  .card {
    width: 95%;
    max-width: 550px;
    border-radius: 15px;
    padding: 60px 20px;
    margin-top: 40px;
    &.border-success {
      border: 4px solid green;
    }
    &.border-error {
      border: 4px solid red;
    }
  }
}
</style>
