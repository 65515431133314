<template>
  <section class="filters">
    <section class="ex-panels py-3 mt-2 mx-1 d-flex flex-column">
      <v-expansion-panels multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>Category</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedCategory"
              @change="fetchSubCategories"
              :items="categoryItems"
              item-text="title"
              item-value="id"
              label="Categories List"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
              dense
              clearable
              dark
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Sub Category</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedSubCategory"
              :items="subCategoryItems"
              :disabled="!selectedCategory"
              :loading="!!selectedCategory && subCategoryItems.length <= 0"
              item-text="title"
              item-value="id"
              :label="selectedCategory? 'Sub Categories List' : 'Select a Category First'"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
              dense
              clearable
              dark
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Specification</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedSpecifications"
              :items="existSpecifications"
              item-text="title"
              item-value="id"
              class="Specifications-select"
              label="Specifications List"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
              dense
              clearable
              dark
              multiple
              chips
              small-chips
              deletable-chips
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <v-expansion-panel>
          <v-expansion-panel-header>Size</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1 class="white--text mb-3">Width:</h1>
            <v-text-field
              :value="widthRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(widthRange, 1, $event)"
            ></v-text-field>
            <v-text-field
              :value="widthRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(widthRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="widthRange"
              :min="minWidth"
              :max="maxWidth"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ minWidth }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-1">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ maxWidth }}</p>
              </div>
            </div>

            <v-divider class="white my-4"></v-divider>

            <h1 class="white--text mb-3">height:</h1>
            <v-text-field
              :value="heightRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(heightRange, 1, $event)"
            ></v-text-field>
            <v-text-field
              :value="heightRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(heightRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="heightRange"
              :min="minHeight"
              :max="maxHeight"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ minHeight }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-1">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ maxHeight }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
        <v-expansion-panel>
          <v-expansion-panel-header>Name</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              v-model="name"
              label="name"
              type="text"
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Location</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              v-model="cordinateXSelected"
              label="Cordinate X"
              type="number"
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
            <v-slider
              v-model="cordinateXSelected"
              class="align-center"
              :max="cordinateXMax"
              :min="cordinateXMin"
              hide-details
            >
            </v-slider>
            <div class="d-flex justify-space-between mb-4">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ cordinateXMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-2">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ cordinateXMax }}</p>
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <v-text-field
              v-model="cordinateYSelected"
              label="Cordinate Y"
              type="number"
              class=""
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
            <v-slider
              v-model="cordinateYSelected"
              class="align-center"
              :max="cordinateYMax"
              :min="cordinateYMin"
              hide-details
            >
            </v-slider>
            <div class="d-flex justify-space-between mb-4">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ cordinateXMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-2">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ cordinateYMax }}</p>
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <v-text-field
              v-model="selectedRadius"
              label="Radius"
              type="number"
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
            <v-slider
              v-model="selectedRadius"
              class="align-center"
              :max="radiusMax"
              :min="radiusMin"
              hide-details
            >
            </v-slider>
            <div class="d-flex justify-space-between mb-4">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ radiusMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-2">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ radiusMax }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Price</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              :value="priceRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(priceRange, 0, $event)"
            ></v-text-field>
            <v-text-field
              :value="priceRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(priceRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="priceRange"
              :max="priceMax"
              :min="priceMin"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ priceMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-5">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ priceMax }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <section class="actions mt-2 d-flex justify-end">

      <v-btn
        dark
        outlined
        color="error"
        class="px-5 mr-1 "
        @click="resetFilters"
        >Reset</v-btn
      >
      <v-btn
        dark
        outlined
        color="success"
        @click="submitFilters"
        >Apply</v-btn
      >
      </section>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  // props: [
  //   "selectedSubCategory",
  //   "widthRange",
  //   "heightRange",
  //   "name",
  //   "cordinateXSelected",
  //   "cordinateYSelected",
  //   "selectedRadius",
  //   "priceRange",
  // ],
  data() {
    return {
      categoryItems: [],
      selectedCategory: 0,
      subCategoryItems: [],
      selectedSubCategory: 0,
      // Specification
      selectedSpecifications: [],
      existSpecifications: [],
      // Specification
      // size
      // minWidth: 0,
      // maxWidth: 16,
      // widthRange: [0, 16],
      // minHeight: 0,
      // maxHeight: 16,
      // heightRange: [0, 16],
      // size
      name: null,

      // location
      cordinateXMin: 0,
      cordinateXMax: 0,
      cordinateXSelected: 0,

      cordinateYMin: 0,
      cordinateYMax: 0,
      cordinateYSelected: 0,

      radiusMin: 0,
      radiusMax: 1000,
      selectedRadius: 0,
      // location

      // price
      priceMin: 0,
      priceMax: 0,
      priceRange: [0, 0],
      // price
    };
  },
  created() {
    this.fetchCategories();
    this.fetchTags();
    this.fetchMaxCordinate();
    this.fetchPriceRange();
  },
  methods: {
    ...mapActions([
      "getCategories",
      "getSubCategories",
      "getTags",
      "getMaxCordinate",
      "getPriceRange",
    ]),
    fetchCategories() {
      this.getCategories().then((result) => {
        this.categoryItems = result.result;
        console.log('cI:',this.categoryItems);
      });
    },
    fetchSubCategories() {
      if (this.selectedCategory) {
        this.getSubCategories(this.selectedCategory).then((result) => {
          this.subCategoryItems = result.result;
        });
      }
    },
    fetchTags() {
        this.getTags().then((result) => {
          this.existSpecifications = result.result;
        });
    },
    fetchMaxCordinate() {
      this.getMaxCordinate().then((result) => {
        console.log(result);
        this.cordinateXMax = result.result.maxCoordinateX;
        this.cordinateYMax = result.result.maxCoordinateY;
      });
    },
    fetchPriceRange() {
      this.getPriceRange().then((result) => {
        this.priceMin = result.result.minPrice;
        this.priceMax = result.result.maxPrice;
        this.priceRange[0] = result.result.minPrice;
        this.priceRange[1] = result.result.maxPrice;
      });
    },
    submitFilters() {
      this.$emit(
        "submitFilters",
        this.selectedSubCategory,
        this.name,
        this.cordinateXSelected,
        this.cordinateYSelected,
        this.selectedRadius,
        this.priceRange,
        this.selectedSpecifications,
      );
    },
    resetFilters(){
      this.selectedCategory = 0
      this.selectedSubCategory =0
        this.name = null
        this.cordinateXSelected = 0
        this.cordinateYSelected = 0
        this.selectedRadius = 0
        this.priceRange = [0,23300]
        this.selectedSpecifications = []
    }
  },
};
</script>

<style lang="scss" scoped>
.ex-panels {
  .v-expansion-panel-header {
    background: black !important;
    color: #ff6600;
    border-bottom: 1px solid white;
    user-select: none;
  }
  ::v-deep .v-expansion-panel-header__icon {
    i {
      &::before {
        color: white !important;
      }
    }
  }
  ::v-deep.v-expansion-panel-content__wrap {
    background: rgb(22, 22, 22) !important;
    padding: 20px 10px;
  }
  .Specifications-select{
    ::v-deep .v-select__selections{
     margin-top: 5px;
    }
  }
}
</style>
