<template>
  <section class="first d-flex flex-column align-center justify-center mt-10">
    
    <!-- <div class="mt-16 d-flex flex-column justify-center align-center">
      <h1 class="white--text">Plants and Pots</h1>
      <h1 class="white--text">For Your Home</h1>
      <button class="px-7 py-5 mt-7 grey  white--text shop-button">Shop Now</button>
    </div> -->
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.first {
  min-height: 100vh;
  background: url('../../assets/images/Poster_Lands_of_Azollite.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgb(0, 0, 0);
  h1 {
    font-size: 50px;
    font-weight: 40;
  }
}
</style>
