export default (to, from, next) => {
  if (
    localStorage.getItem("user") != null &&
    localStorage.getItem("user").length > 0 &&
    localStorage.getItem("token") != null &&
    localStorage.getItem("token").length > 0 &&
    sessionStorage.getItem("defaultAddress") != null &&
    sessionStorage.getItem("defaultAddress").length > 0
  ) {
    // verify with firebase or jwt
    next();
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    sessionStorage.removeItem("defultAddress");
    next("/");
    // next()
  }
};
