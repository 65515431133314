<template>
  <section class="nav-drawer d-flex flex-column align-center">
    <div class="mt-16">
      <div class="items mb-15">
        <h1 style="animation-delay: 0.1s" @click="click('/shop')">Shop</h1>
        <h1 style="animation-delay: 0.2s" @click="click('/map')">Map</h1>

        <h1 style="animation-delay: 0.3s">
          <a class="white--text" href="https://doc.landsofazollite.com/"
            >Our Story</a
          >
        </h1>
        <!-- <h1 style="animation-delay: 0.3s" >Journal</h1> -->
        <a href="/#contact" @click="click('')"
          ><h1 style="animation-delay: 0.4s">Contact</h1></a
        >
      </div>
      <div class="icons d-flex justify-center">
        <v-icon color="grey" style="animation-delay: 0.6s"
          >mdi-instagram</v-icon
        >
        <v-icon color="grey" class="mx-4" style="animation-delay: 0.7s"
          >mdi-facebook</v-icon
        >
        <v-icon color="grey" style="animation-delay: 0.8s">mdi-twitter</v-icon>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    const body = document.body;
    body.style.cssText = "height:100vh; overflow:hidden;";
  },
  beforeDestroy() {
    const body = document.body;
    body.style.cssText = "height: ''; overflow: '';";
  },
  methods: {
    click(path) {
      this.$emit("click", path);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer {
  position: fixed;
  top: 88px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #38383b;
  div {
    .items {
      cursor: pointer;
      h1 {
        animation: slide-down 1s ease forwards;
        opacity: 0;
        color: white;
        font-size: 40px;
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .icons {
      .v-icon {
        opacity: 0;
        animation: slide-down 1s ease forwards;
      }
    }
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(90px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
