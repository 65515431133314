<template>
  <section class="profile routes d-flex justify-center">
    <div class="profile-card">
      <v-row>
        <v-col>
          <section class="">
            <div class="head pa-3 grey darken-4">
              <h2>profile</h2>
            </div>
            <v-divider class="grey mb-5"></v-divider>
            <div class="loading" v-if="isLoading">
              <loading-spinner></loading-spinner>
            </div>
            <section class="content pa-4" v-else>
              <div class="d-flex align-center justify-space-between mb-5">
                <h1 class="">Wallet ID:</h1>
                <p>{{ getDefaultAddress.base58 }}</p>
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  :rules="rules.email_rules"
                  outlined
                  dense
                  clearabl
                  dark
                  class="mr-3"
                ></v-text-field>
                <v-btn
                  outlined
                  height="40"
                  color="yellow"
                  :loading="isLoading"
                  @click="emailVerify"
                  v-if="!emailVerified"
                  >email verify</v-btn
                >
                <div
                  class="d-flex align-center px-1 br-5"
                  style="
                    max-height: 40px;
                    border: 2px solid green;
                    cursor: not-allowed;
                  "
                  v-else
                >
                  <p class="green--text">Email Verified</p>
                  <v-icon right color="green">mdi-check</v-icon>
                </div>
              </div>
              <v-text-field
                v-model="password"
                label="Password"
                type=""
                outlined
                dense
                dark
                clearable
              ></v-text-field>
              <div class="d-flex align-center justify-space-between">
                <h1 class="">Telegram:</h1>
                <p>Not Connected</p>
              </div>
              <div class="d-flex align-center justify-space-between">
                <h1 class="">2Fa:</h1>
                <p>Not Enabled</p>
              </div>
            </section>
          </section>
        </v-col>
      </v-row>
    </div>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      color="green accent-4 "
      elevation="100"
      absolute
      centerd
      shaped
      top
      transition="slide-y-transition"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      emailVerified: false,
      isLoading: false,
      message: "",
      snackbar: false,
    };
  },
  computed: {
    ...mapGetters(["getDefaultAddress", "rules"]),
  },
  created() {
    this.gerUserInfo();
  },
  methods: {
    ...mapActions(["getProfileInfo", "sendEmailVerify"]),
    gerUserInfo() {
      this.isLoading = true;
      this.getProfileInfo().then((result) => {
        console.log(result);
        this.email = result.result.email;
        if (result.result.email_verified_at) {
          this.emailVerified = true;
        }
        this.isLoading = false;
      });
    },
    emailVerify() {
      this.isLoading = true;
      this.sendEmailVerify().then((result) => {
        console.log(result);
        this.isLoading = false;
        this.snackbar = true;
        this.message = result.message;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  color: white;
  .profile-card {
    width: 90%;
    max-width: 600px;
    border: 2px solid grey;
    border-radius: 5px;
    .loading{
      height: 256px;
    }
  }
}
::v-deep.v-text-field {
  fieldset {
    color: rgb(255, 255, 255) !important;
  }
}
</style>
