<template>
  <section class="home">
    <FirstSection />

    <Socials />
    <!-- <ImageCarousel />
    <GrowUp /> -->

    <LandsOfAzolite></LandsOfAzolite>
    <!-- <third-section></third-section> -->
    <!-- <Journal /> -->
    <game-mode></game-mode>
    <Contact></Contact>
  </section>
</template>

<script>
import FirstSection from "../components/home/FirstSection.vue";
// import ImageCarousel from "../components/home/ImageCarousel.vue";
import Socials from "../components/home/Socials.vue";
import LandsOfAzolite from "../components/home/LandsOfAzolite.vue";
// import ThirdSection from "../components/home/ThirdSection.vue";
// import Journal from "../components/home/Journal.vue";
// import GrowUp from "../components/home/GrowUp.vue";
import GameMode from "../components/home/GameMode.vue";
import Contact from "../views/Contact.vue";

export default {
  name: "Home",

  components: {
    FirstSection,
    LandsOfAzolite,

    // GrowUp,
    // ImageCarousel,
    Socials,
    // ThirdSection,
    // Journal,
    GameMode,
    Contact
  },
  
};
</script>
<style lang="scss" scoped>
</style>
