const state = {
  tronWebObject: {},
  isTronAccessible: false,
  isTronConnected: "",
  defaultAddress: {},
  base58: "",
  loginStatus: "",
};

const getters = {
  getTronWebObject(state) {
    return state.tronWebObject;
  },
  getTronAccessStatus(state) {
    return state.isTronAccessible;
  },
  getTronConnectionStatus(state) {
    return state.isTronConnected;
  },
  getDefaultAddress(state) {
    return state.defaultAddress
      ? state.defaultAddress
      : JSON.parse(sessionStorage.getItem("defaultAddress"));
  },
  getLoginStatus(state) {
    return state.loginStatus;
  },
};

const mutations = {
  setTronWebObject(state, tronWeb) {
    state.tronWebObject = tronWeb;
  },
  setTronAccessStatus(state, hasAccess) {
    state.isTronAccessible = hasAccess;
  },
  setTronConnectionStatus(state, isConnected) {
    state.isTronConnected = isConnected;
  },
  setDefaultAddress(state, defaultAddr) {
    state.defaultAddress = defaultAddr;
  },
  setLoginStatus(state, isLogin) {
    state.loginStatus = isLogin;
  },
  tronBotResetStore(state) {
    state.tronWebObject = {};
    state.isTronAccessible = false;
    state.isTronConnected = "";
    state.defaultAddress = {};
    state.base58 = "";
    state.loginStatus = "";
  },
};

const actions = {
  setTronWebObject({ commit }, payload) {
    commit("setTronWebObject", payload);
  },
  setTronAccessStatus({ commit }, payload) {
    commit("setTronAccessStatus", payload);
  },
  setTronConnectionStatus({ commit }, payload) {
    commit("setTronConnectionStatus", payload);
  },
  setDefaultAddress({ commit }, payload) {
    commit("setDefaultAddress", payload);
    sessionStorage.setItem("defaultAddress", JSON.stringify(payload));
  },
  setLoginStatus({ commit }, payload) {
    commit("setLoginStatus", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
