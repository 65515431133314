<template>
  <div class="tron-component" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "TronComponent",
  data() {
    return {
      tronWeb: "",
    };
  },
  computed: {
    ...mapGetters(["getDefaultAddress", "getLoginStatus"]),
  },
  mounted() {
    this.setTronWebObj();
  },
  methods: {
    // Vuex Map Actions
    ...mapActions([
      "setTronAccessStatus",
      "setTronWebObject",
      "setTronConnectionStatus",
      "setDefaultAddress",
      "setLoginStatus",
    ]),
    ...mapMutations(["tronBotResetStore", "userClear"]),
    //

    backToLogin() {
      sessionStorage.clear();
      localStorage.clear();
      if (this.$route.name != "Home" && this.$route.name != "shop" && this.$route.name != "map" && this.$route.name != 'verify-email') {
        this.tronBotResetStore();
        this.userClear();
        this.$router.push({ name: "Home" });
      }
    },

    setTronWebObj() {
      try {
        setInterval(() => {
          if (window.tronWeb !== undefined) {
            this.setTronAccessStatus(true);
            if (window.tronWeb.ready) {
              this.tronWeb = window.tronWeb;
              if (this.getDefaultAddress != this.tronWeb.defaultAddress) {
                this.setTronWebObject(this.tronWeb);
                this.setTronConnectionStatus(true);
                this.setLoginStatus(true);

                this.setDefaultAddress(this.tronWeb.defaultAddress);
              }
            } else {
              // TRONWEB IS ACCESSIBLE BUT NOT CONNECTED(NOT LOGGED IN)!

              this.setTronConnectionStatus(false);
              this.setLoginStatus(false);

              this.backToLogin();
            }
          } else {
            // location.reload();
            this.setTronAccessStatus(false);
            sessionStorage.clear();
          }
        }, 2000);
      } catch (e) {
        // console.log(e)
      }
    },
  },
  watch: {
    getDefaultAddress(newVal, oldVal) {
      if (
        this.getDefaultAddress.base58 &&
        oldVal.base58 &&
        newVal.base58 != oldVal.base58
      ) {
        sessionStorage.clear();
        localStorage.clear();
        this.tronBotResetStore();
        this.userClear();
        if (this.$route.name != "Home" && this.$route.name != "shop" && this.$route.name != 'verify-email') {
          this.$router.push({ name: "Home" });
        }
      }
    },
  },
};
</script>
