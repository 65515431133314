import Vue from "vue";
import Vuex from "vuex";
import mainApi from "../plugins/axios/axios.js";

import tronBot from "./modules/tronBot";
import rules from "./modules/rules";
import auth from "./modules/auth";
import shop from "./modules/shop";
import profile from "./modules/profile";
import assets from "./modules/assets";
import alert from './modules/alert'
import map from './modules/map'
import { setTimeout } from "core-js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        walletModal: false,
        newPage: "",
    },
    getters: {
        walletModal(state) {
            return state.walletModal;
        },
        newPage(state) {
            return state.newPage;
        },
    },
    mutations: {
        walletModal(state, payload) {
            state.walletModal = payload;
        },
        changePage(state, payload) {
            state.newPage = payload;
        },
    },
    actions: {
        async sendContactForm(_, payload) {
            console.log(payload);
            try {
                await mainApi.post("landing/contact_us", payload);

                //

                //
                return true;
            } catch (error) {
                return false;
            }
        },


        getPaymentList(_, page) {
            return new Promise((resolve, reject) => {
                mainApi
                    .get(`/users/payments`, {
                        params: { page: page }
                    })
                    .then((result) => {
                        resolve(result.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            });
        },
        createPayment(_, info) {
            console.log(info);
            return new Promise((resolve, reject) => {
                mainApi
                    .post(`/users/payments`, info)
                    .then((result) => {
                        resolve(result.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            });
        },
        getUserCoins() {
            return new Promise((resolve, reject) => {
                mainApi
                    .get(`/users/coins`)
                    .then((result) => {
                        resolve(result.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            });
        },


        openWalletModal({ commit }) {
            console.log('hello');
            commit("walletModal", true);
            setTimeout(() => {
                commit("walletModal", false);
            }, 500);
        },
        changePage({ commit }, payload) {
            commit("changePage", payload);

            setTimeout(() => {
                commit("changePage", "");
            }, 100);
        },
    },
    modules: {
        tronBot,
        rules,
        auth,
        shop,
        profile,
        assets,
        alert,
        map
    },
});